import { imgBackground } from 'assets'
import { useUpdateQueries } from 'hooks/useUpdateQueries'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Header } from 'stories/layouts'
import { Footer } from 'stories/layouts'

const footerNavigation = [
  { name: 'Alexa', href: 'https://developer.amazon.com/en-US/alexa' },
  { name: 'Google Assistant', href: 'https://www.google.com' },
  { name: 'Siri', href: 'https://www.apple.com/siri/' },
]

export const MainLayout = (props: any) => {
  const { children } = props
  const { data } = useUpdateQueries()
  const [oldData, setOldData] = useState<Array<any>>([])

  useEffect(() => {
    if (!data) return
    if (data.length == oldData.length) {
      data.forEach((item: any, index: number) => {
        if (item.status != oldData[index].status) {
          toast(`"${item.title}" is completed.`, { type: 'info' })
        }
      })
    }
    setOldData(data)
  }, [data])

  return (
    <div className="relative bg-white overflow-hidden h-full-screen">
      <img src={imgBackground} className="absolute right-0 bottom-0 top-0 h-full max-w-none opacity-[0.15]" />
      <div className="home-left-bottom" />
      <Header />
      <main className="relative mx-auto main px-8">{children}</main>
      <Footer navigation={footerNavigation} />
    </div>
  )
}
