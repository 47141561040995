import {
  KeyIcon,
  PencilAltIcon,
  PlusIcon,
  RefreshIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { Tooltip } from '@material-tailwind/react'
import { countPerPage as _countPerPage } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { fetchUserList, formatPassword } from 'services/apis'
import { Button, Input, Pagination } from 'stories/components'
import { confirm } from 'utils'
import { formatTime } from 'utils/convertor'
import { InviteUserModal } from './userModal/InviteUserModal'
import { UpdateUserModal } from './userModal/updateUserModal'

export function UserManagement() {
  const [list, setList] = useState<Array<Record<string, any>>>([])
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)

  const [isLoading, setLoading] = useState(false)
  const [isShowInvite, setShowInvite] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [countPerPage, setCountPerPage] = useState(_countPerPage)

  const [filters, setFilters] = useState<Record<string, string>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    refetch(filters)
    setIsGetUsersOnce(true)
  }, [pageNum, countPerPage])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && refetch(filters), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const refetch = (filter: any) => {
    setLoading(true)
    fetchUserList({
      ...filter,
      from: pageNum * countPerPage,
      count: countPerPage,
    }).then(({ data, total }) => {
      setList(data)
      setTotal(total)
      setLoading(false)
    })
  }

  const onInvite = () => {
    setShowInvite(true)
  }

  const onChangeFilter = (key: 'query' | 'orderBy' | 'orderDir', value: string) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else refetch(newFilters)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    setCountPerPage(count)
    setPageNum(0)
  }

  const onDetails = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: any) => {
    if (e.defaultPrevented) return
  }

  const onResetPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    const result = await confirm('Do you want to reset the password?')
    if (!result) return

    setLoading(true)
    formatPassword(item.id)
      .then(() => toast('Successfully updated', { type: 'success' }))
      .finally(() => setLoading(false))
  }

  const onRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
  }

  const onCloseInviteModal = (needRefresh = false) => {
    setShowInvite(false)
    needRefresh && refetch(filters)
  }

  const onCloseUpdateModal = (needRefresh = false) => {
    setSelectedUser(null)
    needRefresh && refetch(filters)
  }

  const renderHeader = (title: string, sortable: boolean = false, key: string, sortOrder: number = 1) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3" key={title}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      refetch(newFilters)
    }

    return (
      <th scope="col" className="px-6 py-3" key={title}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <SortAscendingIcon className="w-3 h-3 ml-2" />
            ) : (
              <SortDescendingIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  const sortableHeaders = [
    { title: 'Username', key: 'username' },
    { title: 'Email', key: 'email' },
    { title: 'Role', key: 'role' },
    { title: 'Created At', key: 'createdAt' },
    { title: 'Status', key: 'state' },
  ]

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">User Management</p>
        <Button onClick={onInvite}>
          <div className="flex items-center">
            Invite <PlusIcon className="w-4 h-4 ml-2" />
          </div>
        </Button>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-2">
        <Input
          type="search"
          title="Search..."
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-teal-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              {sortableHeaders.map(({ title, key }) =>
                renderHeader(title, true, key, filters.orderBy == key ? parseInt(filters.orderDir) : 0),
              )}
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map &&
              typeof list.map == 'function' &&
              list.map((item: any, index: number) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                  key={`${index}-${item.id}`}
                  onClick={(e) => onDetails(e, item)}
                >
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + pageNum * countPerPage + 1}
                  </th>
                  <td className="px-6 py-4">{item.username}</td>
                  <td className="px-6 py-4">{item.email}</td>
                  <td className="px-6 py-4 capitalize">{item.role}</td>
                  <td className="px-6 py-4">{formatTime(item.createdAt)}</td>
                  <td className="px-6 py-4 uppercase">{item.state}</td>
                  <td className="px-6 py-4">
                    {item.state != 'invited' && (
                      <Tooltip content="Edit">
                        <button
                          className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                          onClick={(e) => setSelectedUser(item)}
                        >
                          <PencilAltIcon className="w-5 h-5" />
                        </button>
                      </Tooltip>
                    )}

                    {item.state != 'invited' && (
                      <Tooltip content="Reset Password">
                        <button
                          className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                          onClick={(e) => onResetPassword(e, item)}
                        >
                          <KeyIcon className="w-5 h-5" />
                        </button>
                      </Tooltip>
                    )}

                    <Tooltip content="Delete">
                      <button
                        className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                        onClick={(e) => onRemove(e, item)}
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {list && (
          <Pagination
            totalCount={total}
            itemCountPerPage={countPerPage}
            pageNum={pageNum}
            maxPageCount={10}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
          />
        )}

        {isLoading && (
          <div className="w-full h-96 absolute left-0 top-0 flex place-items-center bg-teal-50/50">
            <div className="w-full items-center flex flex-col">
              <RefreshIcon className="inline w-4 h-4 mr-3 text-black animate-spin" />
            </div>
          </div>
        )}
      </div>

      {isShowInvite && <InviteUserModal onClose={onCloseInviteModal} />}
      {selectedUser && <UpdateUserModal user={selectedUser} onClose={onCloseUpdateModal} />}
    </div>
  )
}
