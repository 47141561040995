import {
  API_GET_USERS,
  API_USERS_INVITE,
  API_USERS_RESET_PASSWORD,
  API_USERS_UPDATE,
  API_USER_CHECK_PASSPORT,
  API_USER_FORGOTPASSWORD,
  API_USER_RESET_PASSWORD,
  API_USER_UPDATE_MY_PASSWORD,
  API_USER_UPDATE_PROFILE,
  API_USER_UPDATE_PWD,
} from 'config'
import { toast } from 'react-toastify'
import Api from 'services/api'

export const updatePassword = (userId: number, password: string) => {
  return Api.put(API_USER_UPDATE_PWD, { password }, { userId })
}

export const forgotPassword = async (params: any) => {
  await Api.post(API_USER_FORGOTPASSWORD, params)

  toast('Email is sent successfully. Please confirm it.', { type: 'success' })
}

export const checkPassport = (selector: string, token: string) => {
  return Api.get(API_USER_CHECK_PASSPORT, {}, { selector, token })
}

export const resetPassword = (selector: string, token: string, password: string) => {
  return Api.post(API_USER_RESET_PASSWORD, { password }, { selector, token })
}

export const updateProfile = (data: object) => {
  return Api.put(API_USER_UPDATE_PROFILE, data)
}

export const updateMyPassword = (data: object) => {
  return Api.post(API_USER_UPDATE_MY_PASSWORD, data)
}

export const fetchUserList = (data: any) => {
  return Api.get(API_GET_USERS, data)
}

export const formatPassword = (userId: string) => {
  return Api.post(API_USERS_RESET_PASSWORD, {}, { userId })
}

export const inviteUser = (data: { email: string; role: string }) => {
  return Api.post(API_USERS_INVITE, data)
}

export const updateUser = (userId: number, data: { role: string; state: string }) => {
  return Api.put(API_USERS_UPDATE, data, { userId })
}
