import { countPerPage as _countPerPage } from 'config'
import packageInfo from '../../../package.json'

export function About() {
  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">About</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="w-full">
        <p>
          Version: Answervox <b>v{packageInfo.version}</b>
        </p>
        <a href="#">Release Notes</a>
      </div>
    </div>
  )
}
