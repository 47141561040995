import {
  ClipboardIcon,
  CollectionIcon,
  DownloadIcon,
  RefreshIcon,
  ShareIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { Tooltip } from '@material-tailwind/react'
import { onSetActiveQuery } from 'actions'
import { countPerPage, defaultKey, ExcelStatus, QueryType, s3Url } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteExcelItem, filterExcelList } from 'services/apis'
import { Input, Pagination, Select } from 'stories/components'
import { confirm } from 'utils'
import { formatTime } from 'utils/convertor'
import { ShareExcelModal } from './excelModal/shareExcelModal'

const statusOptions = {
  '': 'All',
  completed: 'Completed',
  inprogress: 'InProgress',
}

export function AllExcels() {
  const [list, setList] = useState<Array<Record<string, any>>>([])
  const navigate = useNavigate()
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)

  const [filters, setFilters] = useState<Record<string, string>>({
    query: '',
    status: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [sharingItem, setSharingItem] = useState<Record<string, any> | null>(null)
  const [_countPerPage, setCountPerPage] = useState(countPerPage)

  const dispatch = useDispatch()

  useEffect(() => {
    refetch(filters)
    setIsGetUsersOnce(true)
  }, [pageNum, _countPerPage])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && refetch(filters), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const refetch = (filter: any) => {
    setLoading(true)
    filterExcelList({
      ...filter,
      from: pageNum * _countPerPage,
      count: _countPerPage,
    }).then(({ data, total }) => {
      setList(data)
      setTotal(total)
      setLoading(false)
    })
  }

  const onChangeFilter = (key: 'query' | 'status' | 'orderBy' | 'orderDir', value: string) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else refetch(newFilters)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    setCountPerPage(count)
    setPageNum(0)
  }

  const renderType = (type: QueryType) => {
    switch (type) {
      case QueryType.EXCEL:
        return <CollectionIcon className="w-5 h-5 text-teal-500" />
      case QueryType.SINGLE:
        return <ClipboardIcon className="w-5 h-5 text-yellow-500" />
    }
  }

  const renderStatus = (excelStatus: ExcelStatus) => {
    switch (excelStatus) {
      case ExcelStatus.INPROGRESS:
        return (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-200 dark:text-yellow-900">
            In Progress
          </span>
        )
      case ExcelStatus.COMPLETED:
        return (
          <span className="bg-teal-100 text-teal-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-teal-200 dark:text-teal-900">
            Completed
          </span>
        )
    }
  }

  const onDetails = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: any) => {
    if (e.defaultPrevented) return
    if (item.status == ExcelStatus.INPROGRESS) {
      toast('This item is still In Progress', { type: 'warning' })
      return
    }
    if (item.type == QueryType.EXCEL) {
      dispatch(onSetActiveQuery(null, QueryType.EXCEL))
      navigate(`/queries/${item.excelId}`)
    } else if (item.type == QueryType.SINGLE) {
      dispatch(onSetActiveQuery(null, QueryType.SINGLE))
      navigate(`/queries/${item.excelId}/${defaultKey}`, {
        state: { query: item.title },
      })
    }
  }

  const onDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    const url = `${s3Url}/${item.excelId}/target.xls`
    window.open(url, '_blank')
  }

  const onShare = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    setSharingItem(item)
  }

  const onRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    const result = await confirm('Do you want to remove this query?')
    if (result) {
      const { excelId } = item
      await deleteExcelItem(excelId)
      refetch(filters)
    }
  }

  const renderHeader = (title: string, sortable: boolean = false, key: string, sortOrder: number = 1) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3" key={title}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      refetch(newFilters)
    }

    return (
      <th scope="col" className="px-6 py-3" key={title}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <SortAscendingIcon className="w-3 h-3 ml-2" />
            ) : (
              <SortDescendingIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  const sortableHeaders = [
    { title: 'Type', key: 'type' },
    { title: 'Title / Query', key: 'title' },
    { title: 'Email', key: 'email' },
    { title: 'Query Count', key: 'count' },
    { title: 'Created At', key: 'createdAt' },
    { title: 'Status', key: 'status' },
  ]

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">All Query Lists</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-2">
        <Input
          type="search"
          title="Search..."
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Select
          id="selectStatus"
          title="Status"
          value={filters.status}
          options={statusOptions}
          onChange={(value) => onChangeFilter('status', value)}
        />
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-teal-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              {sortableHeaders.map(({ title, key }) =>
                renderHeader(title, true, key, filters.orderBy == key ? parseInt(filters.orderDir) : 0),
              )}
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map &&
              typeof list.map == 'function' &&
              list.map((item: any, index: number) => {
                const isCompleted = item.status === ExcelStatus.COMPLETED
                const completedCount = item.status === ExcelStatus.COMPLETED ? item.count : item.completedCount
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                    key={`${index}-${item.excelId}`}
                    onClick={(e) => onDetails(e, item)}
                  >
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + pageNum * _countPerPage + 1}
                    </th>
                    <td className="px-4 py-4">{renderType(item.type)}</td>
                    <td className="px-4 py-4">{item.title}</td>
                    <td className="px-4 py-4">{item.email}</td>
                    <td className="px-4 py-4">
                      <div>
                        {completedCount} / {item.count}
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${(completedCount / item.count) * 100}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4">{formatTime(item.createdAt)}</td>
                    <td className="px-4 py-4">{renderStatus(item.status)}</td>
                    <td className="px-4 py-2 text-right">
                      {item.type == QueryType.EXCEL && item.status === ExcelStatus.COMPLETED && (
                        <Tooltip content="Download">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onDownload(e, item)}
                          >
                            <DownloadIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                      {isCompleted && (
                        <Tooltip content="Share">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onShare(e, item)}
                          >
                            <ShareIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                      {item.status === ExcelStatus.COMPLETED && (
                        <Tooltip content="Delete">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onRemove(e, item)}
                          >
                            <TrashIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {list && (
          <Pagination
            totalCount={total}
            itemCountPerPage={_countPerPage}
            pageNum={pageNum}
            maxPageCount={10}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
          />
        )}

        {isLoading && (
          <div className="w-full h-96 absolute left-0 top-0 flex place-items-center bg-teal-50/50">
            <div className="w-full items-center flex flex-col">
              <RefreshIcon className="inline w-4 h-4 mr-3 text-black animate-spin" />
            </div>
          </div>
        )}

        {sharingItem && <ShareExcelModal onClose={() => setSharingItem(null)} id={sharingItem.id} />}
      </div>
    </div>
  )
}
