import { API_USER_SIGN_IN, API_USER_SIGN_ON } from 'config'
import type { Dispatch } from 'react'
import { toast } from 'react-toastify'
import Api from 'services/api'
import { AUTH_LOGOUT, AUTH_TOKEN_SET, SIGNIN_USER_REQUEST } from './types'

export function signInRequest(params: any) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: SIGNIN_USER_REQUEST })
    const queryParams = { ...params }
    const response = await Api.post(API_USER_SIGN_IN, queryParams)
    const { token, user } = response

    toast('Authorized', { type: 'success' })
    return dispatch({ type: AUTH_TOKEN_SET, token, user })
  }
}

export function signOnRequest(params: any) {
  return async (dispatch: Dispatch<any>) => {
    const queryParams = { ...params }
    const response = await Api.post(API_USER_SIGN_ON, queryParams)
    const { token, user } = response

    toast('Registered account successfully', { type: 'success' })
    return dispatch({ type: AUTH_TOKEN_SET, token, user })
  }
}

export function logout() {
  return { type: AUTH_LOGOUT }
}
