import { ForgotPassword, Login, Register, ResetPassword } from 'pages'
import { Routes, Route, Navigate } from 'react-router-dom'

export function AuthRoute() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:selector/:token" element={<ResetPassword />} />
      <Route path="/*" element={<Navigate replace to="/login" />} />
    </Routes>
  )
}
