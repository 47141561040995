import type { QueryType } from 'config'
import type { Dispatch } from 'react'
import { ON_SET_ACTIVE_QUERY, SET_QUERY_LIST } from './types'

export function onSetActiveQuery(queryData: Record<string, string> | null, queryType: QueryType) {
  return {
    type: ON_SET_ACTIVE_QUERY,
    queryData,
    queryType,
  }
}

export function setQueryList(data: any) {
  return async (dispatch: Dispatch<any>) => {
    return dispatch({ type: SET_QUERY_LIST, data })
  }
}
