// import imgHome from 'assets/home.jpg'
import { Button, Input } from 'stories/components'
import { useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { checkPassport, resetPassword } from 'services/apis'
import { toast } from 'react-toastify'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  password: '',
  confirmPassword: '',
}

export function ResetPassword(props: any) {
  const auth = useSelector((state: any) => state.auth)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [isLoading, setLoading] = useState(false)
  const { selector, token } = useParams()
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (auth.isAuthenticated || !selector || !token) {
      navigate('/login')
      return
    }
    setLoading(true)
    checkPassport(selector, token)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        toast('Invalid token', { type: 'warning' })
      })
  }, [])

  const onSubmit = async () => {
    if (!selector || !token) return

    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.password) newErrors.password = 'Required'
    if (!inputStates.confirmPassword) newErrors.confirmPassword = 'Required'
    if (inputStates.password != inputStates.confirmPassword) newErrors.confirmPassword = 'Type again'
    setErrors(newErrors)

    if (newErrors.password || newErrors.confirmPassword) return

    resetPassword(selector, token, inputStates.password)
      .then(() => {
        toast('Password is reset.', { type: 'info' })
        navigate('/login')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    let newErrors: any = cloneDeep(errors)
    newState[key] = value
    newErrors[key] = ''
    setInputStates(newState)
    setErrors(newErrors)
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative">
      <div className="absolute top-0 w-full"></div>
      {!auth.isAuthenticated && (
        <div className="max-w-screen-2xl m-auto lg:pt-20 md:pt-5 lg:pl-20 md:pl-3 sm:pt-2 sm-pl-2 mb-10">
          <div className="bg-white drop-shadow-2xl rounded-2xl lg:max-w-md md:max-w-md sm:max-w-sm pl-6 pr-6 pt-8 pb-6 relative">
            <div className="text-base text-teal-500 text-3xl font-black mb-6 ">Reset Password</div>

            <form>
              <Input
                type="password"
                title="Password"
                value={inputStates.password}
                required
                error={errors.password ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('password', value)}
              />

              <Input
                type="password"
                title="Password again"
                value={inputStates.confirmPassword}
                required
                error={errors.confirmPassword ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('confirmPassword', value)}
              />

              <Button className="mt-1" color="teal" full bold onClick={onSubmit} loading={isLoading}>
                <span className="flex w-full items-center justify-center">
                  Reset Password
                  <ChevronRightIcon className="w-5 h-5" />
                </span>
              </Button>

              <Link to="/home">
                <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">I have an account</span>
              </Link>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
