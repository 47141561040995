// import imgHome from 'assets/home.jpg'
import { Button, Input } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
import { useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { forgotPassword } from 'services/apis'
import { toast } from 'react-toastify'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
}

export function ForgotPassword(props: any) {
  useTitle(`Home - ${process.env.REACT_APP_COMPANY}`)

  const auth = useSelector((state: any) => state.auth)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: '',
  })

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    setErrors(newErrors)

    if (!inputStates.email) return

    setLoading(true)
    forgotPassword(inputStates)
      .then(() => {
        toast('Email is sent.', { type: 'info' })
        navigate('/login')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative">
      <div className="absolute top-0 w-full"></div>
      {!auth.isAuthenticated && (
        <div className="max-w-screen-2xl m-auto lg:pt-20 md:pt-5 lg:pl-20 md:pl-3 sm:pt-2 sm-pl-2 mb-10">
          <div className="bg-white drop-shadow-2xl rounded-2xl lg:max-w-md md:max-w-md sm:max-w-sm pl-6 pr-6 pt-8 pb-6 relative">
            <div className="text-base text-teal-500 text-3xl font-black mb-6 ">Forgot Password</div>

            <form>
              <Input
                type="email"
                title="Email"
                value={inputStates.email}
                required
                error={errors.email ? 'Please provide a valid email address.' : ''}
                onChange={(value) => onChange('email', value)}
              />

              <Button className="mt-1" color="teal" full bold onClick={onSubmit} loading={isLoading}>
                <span className="flex w-full items-center justify-center">
                  Forgot Password
                  <ChevronRightIcon className="w-5 h-5" />
                </span>
              </Button>

              <Link to="/home">
                <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">I have an account</span>
              </Link>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
