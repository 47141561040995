import type { Navigation } from 'stories/types'
// import { HomeIcon } from '@heroicons/react/outline'
import { imgLogo } from 'assets'

interface FooterProps {
  navigation: Array<Navigation>
}

export const Footer = ({ navigation }: FooterProps) => (
  <footer className="mx-auto bottom-0 w-full py-2 relative">
    <div className="px-4 sm:px-6 lg:px-8">
      <nav className="flex items-center justify-center flex-wrap" aria-label="Global">
        <div className="flex items-center flex-grow flex-shrink-0 flex-grow-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <a href="#">
              <span className="sr-only">Workflow</span>
              <img alt="Workflow" className="h-8 w-auto" src={imgLogo} />
            </a>
          </div>
        </div>
        <div className="mt-4 md:ml-10 md:pr-4 md:space-x-8 flex flex-wrap items-center">
          {navigation.map((item) => (
            <p key={item.name} className="font-medium text-gray-700 hover:text-gray-900 p-2 flex">
              {item.name}
            </p>
          ))}
          <span className="font-medium text-gray-00 p-2">© 2022 AnswerVox. All rights reserved.</span>
        </div>
      </nav>
      {/* <div className="flex flex-wrap items-center justify-center mt-1">
        <p className="text-gray-500 text-sm text-center">
          This website is for use by real estate and financial business entities and is not intended for individual
          consumers or customers. <br />
          Copyright © 2022 Answervox, LLC. All rights reserved. 12 Penns Trail Suite 138 Newtown, PA 18940
        </p>

        <HomeIcon className="h-14 w-auto m-4" />
      </div> */}
    </div>
  </footer>
)
