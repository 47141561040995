export const API_USER_SIGN_IN = '/auth/login'
export const API_USER_SIGN_ON = '/auth/register'
export const API_USER_FORGOTPASSWORD = '/auth/forgotPassword'
export const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
export const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'

export const API_USER_UPDATE_PWD = '/user/updatePwd/:userId'

export const API_USER_UPDATE_PROFILE = '/user/updateProfile'
export const API_USER_UPDATE_MY_PASSWORD = '/user/updateMyPassword'

export const API_GET_EXCELS = '/excel'
export const API_GET_EXCEL = '/excel/:excelId'
export const API_GET_EXCEL_DETAILS = '/excel/:excelId/:key'
export const API_DELETE_EXCEL = '/excel/:excelId'

export const API_GET_ALL_EXCELS = '/excel/all'

export const API_GET_EXCEL_SHARES = '/excel/share/:id'
export const API_GET_EXCEL_SHARES_BY_USER = '/excel/shareByUser/:id'

export const API_UPLOAD_EXCEL = '/excel'
export const API_UPLOAD_QUERY = '/query'

export const API_GET_USERS = '/users'
export const API_USERS_RESET_PASSWORD = '/users/resetPassword/:userId'
export const API_USERS_INVITE = 'users/invite'
export const API_USERS_UPDATE = 'users/update/:userId'

export const API_SERVER_STATUS = '/server/status'
export const API_SERVER_LOG = '/server/log'
export const API_SERVER_MONITOR = '/server/monitor'
