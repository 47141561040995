import { UploadIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { uploadSingleQuery } from 'services/apis'
import { Button, Input } from 'stories/components'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  query: '',
  email: '',
}

export function UploadQuery() {
  const navigate = useNavigate()
  const auth = useSelector((state: any) => state.auth)

  const [inputStates, setInputStates] = useState({
    ...defaultInputs,
    email: auth.profile.email,
  })
  const [errors, setErrors] = useState({
    query: '',
    email: '',
    file: '',
  })
  const [isLoading, setLoading] = useState(false)

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)

    setErrors({
      ...errors,
      [key]: '',
    })
  }

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.query) newErrors.query = 'Required'
    setErrors(newErrors)
    if (!inputStates.email || !inputStates.query) return

    setLoading(true)
    uploadSingleQuery(inputStates.query, inputStates.email)
      .then(() => {
        toast('Uploaded successfully. Estimated time to completion: 2 minutes', { type: 'success' })
        navigate('/queries')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">Submit Single Query</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="relative xl:w-[50%] w-full">
        <Input
          type="text"
          title="Query"
          value={inputStates.query}
          required
          error={errors.query ? 'Please provide a valid query.' : ''}
          onChange={(value) => onChange('query', value)}
        />
        <Input
          type="email"
          title="Email"
          value={inputStates.email}
          required
          error={errors.email ? 'Please provide a valid email address.' : ''}
          onChange={(value) => onChange('email', value)}
        />

        <Button className="mt-2" color="teal" bold onClick={onSubmit} loading={isLoading}>
          <span className="flex w-full items-center justify-center">
            Submit
            <UploadIcon className="w-5 h-5 ml-2" />
          </span>
        </Button>
      </div>
    </div>
  )
}
