import { API_SERVER_LOG, API_SERVER_MONITOR, API_SERVER_STATUS } from 'config'
import Api from 'services/api'

export const fetchServerStatus = () => {
  return Api.get(API_SERVER_STATUS)
}

export const fetchServerLog = (filters: Record<string, any>) => {
  return Api.get(API_SERVER_LOG, filters)
}

export const fetchServerMonitor = () => {
  return Api.get(API_SERVER_MONITOR)
}
