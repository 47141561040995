import { AuthLayout, MainLayout } from 'layouts'
import { AuthRoute } from 'routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useSelector } from 'react-redux'
import { MainRoute } from 'routes/main.route'
const queryClient = new QueryClient()
function App() {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated)
  return (
    <QueryClientProvider client={queryClient}>
      {isAuthenticated ? (
        <MainLayout>
          <MainRoute />
        </MainLayout>
      ) : (
        <AuthLayout>
          <AuthRoute />
        </AuthLayout>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
