import { Button, Input } from 'stories/components'
import { useState } from 'react'
import { KeyIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'
import { updateMyPassword } from 'services/apis'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  password: '',
  newPassword: '',
  confirmPassword: '',
}

export function UpdatePassword(props: any) {
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  })

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    let hasError = false
    Object.keys(defaultInputs).forEach((key) => {
      if (!(inputStates as any)[key]) {
        newErrors[key] = 'Required'
        hasError = true
      }
    })
    setErrors(newErrors)

    if (!hasError && inputStates.newPassword != inputStates.confirmPassword) {
      newErrors.confirmPassword = 'Invalid'
      hasError = true
    }
    if (hasError) return
    setLoading(true)

    updateMyPassword(inputStates)
      .then(() => {
        toast('Password is updated.', { type: 'info' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    let newErrors: any = cloneDeep(errors)
    newState[key] = value
    newErrors[key] = ''
    setInputStates(newState)
    setErrors(newErrors)
  }

  return (
    <div className="relative w-full">
      <Input
        type="password"
        title="Current Password"
        value={inputStates.password}
        required
        error={errors.password ? 'Please provide a valid password.' : ''}
        onChange={(value) => onChange('password', value)}
      />

      <Input
        type="password"
        title="New Password"
        value={inputStates.newPassword}
        required
        error={errors.newPassword ? 'Please provide a valid password.' : ''}
        onChange={(value) => onChange('newPassword', value)}
      />

      <Input
        type="password"
        title="New Password again"
        value={inputStates.confirmPassword}
        required
        error={errors.confirmPassword ? 'Please provide a valid password.' : ''}
        onChange={(value) => onChange('confirmPassword', value)}
      />

      <Button className="mt-2" color="teal" bold onClick={onSubmit} loading={isLoading}>
        <span className="flex w-full items-center justify-center">
          Update Password
          <KeyIcon className="w-5 h-5 ml-2" />
        </span>
      </Button>
    </div>
  )
}
