import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, RefreshIcon } from '@heroicons/react/outline'
import { imgAmazon, imgGoogle, imgQuestionMark, imgSiri } from 'assets'
import { defaultKey, QueryType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getExcelDetails } from 'services/apis'
import { Button } from 'stories/components'
import { formatTime } from 'utils/convertor'

const noResponse = 'No response received'

export function QueryDetails() {
  const [details, setDetails] = useState<Record<string, string> | null>(null)
  const { queryData, queryType } = useSelector((state: any) => state.query)
  const navigate = useNavigate()
  const { excelId, index: _index } = useParams()
  const [isLoading, setLoading] = useState(true)
  const location = useLocation()

  const index = useMemo(() => {
    if (_index === undefined) return null
    return parseInt(_index)
  }, [_index])

  const key = useMemo(() => {
    if (index === null || !queryData) return defaultKey
    const keys = Object.keys(queryData)
    return keys[index]
  }, [queryData, index])

  const query = useMemo(() => {
    if (!location || !location.state) return ''
    if (queryType == QueryType.SINGLE) return (location.state as any).query as string
    return queryData[key]
  }, [location, index, key])

  const completedDate = useMemo(() => {
    if (!details) return null
    return formatTime(new Date(details.completedDate))
  }, [details])

  const count = useMemo(() => (queryData ? Object.keys(queryData).length : 0), [queryData])

  useEffect(() => {
    if (!excelId || index === null) return
    if (!query) {
      onBack()
      return
    }

    getExcelDetails(excelId, key)
      .then((data) => {
        setDetails(data)
      })
      .finally(() => setLoading(false))
  }, [query, index])

  const onBack = () => {
    if (queryType === QueryType.SINGLE) navigate(`/queries`)
    else navigate(`/queries/${excelId}`)
  }

  const onNavigate = (dir: number) => {
    if (!queryData || index === null) return
    setLoading(true)
    navigate(`/queries/${excelId}/${index + dir}`, { state: { query: queryData[key] } })
  }

  const renderDetails = () => {
    if (!details) return null

    const {
      linkGoogle,
      textGoogle,
      // htmlGoogle,
      imageGoogle,
      googleSnippet,
      googleTextSimilarity,

      linkAlexa,
      textAlexa,
      linkAlexaImg,

      textSiri,
      imageSiri,
    } = details

    return (
      <>
        <div className="shadow-md rounded-3xl mb-10 bg-white" key={`${excelId}-${key}`}>
          <div className="bg-teal-500 px-5 flex rounded-t-3xl overflow-show">
            <img
              src={imgGoogle}
              className="bg-white p-3 w-16 h-16 rounded-full shadow-xl -mt-5 mr-3 border border-solid border-stone-200"
            />
            <p className="text-white font-bold py-4 text-xl">Google</p>
          </div>
          <div className="w-full shadow-xl rounded-b-3xl p-5 flex lg:flex-row flex-col">
            <div className="flex-1 mr-5">
              <pre className="text-md mb-5 whitespace-pre-line">{textGoogle || noResponse}</pre>
              {linkGoogle ? (
                <audio
                  src={linkGoogle}
                  preload="auto"
                  controls
                  className="w-full mb-5"
                  key={`${excelId}-${key}-google-audio`}
                />
              ) : (
                <p className="text-base mb-5">{noResponse}</p>
              )}
              {/* {htmlGoogle && (
                <a href={htmlGoogle} target="_blank">
                  <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500 mb-5">Open HTML</span>
                </a>
              )} */}
              {googleSnippet && (
                <div>
                  <p className="text-lg font-medium mb-2">
                    Google Snippet (<b>{googleTextSimilarity}</b> % match)
                  </p>
                  <p className="text-md mb-5">{googleSnippet}</p>
                </div>
              )}
            </div>
            <div className="lg:w-96 w-full border border-solid border-stone-300">
              {imageGoogle ? <img src={imageGoogle} /> : <p className="text-base p-4">{noResponse}</p>}
            </div>
          </div>
        </div>

        <div className="shadow-md rounded-3xl mb-10 bg-white">
          <div className="bg-teal-500 px-5 flex rounded-t-3xl overflow-show">
            <img
              src={imgAmazon}
              className="bg-white p-3 w-16 h-16 rounded-full shadow-xl -mt-5 mr-3 border border-solid border-stone-200"
            />
            <p className="text-white font-bold py-4 text-xl">Amazon Alexa</p>
          </div>
          <div className="w-full shadow-xl rounded-b-3xl p-5 flex lg:flex-row flex-col">
            <div className="flex-1 mr-5">
              <pre className="text-md mb-5 whitespace-pre-line">{textAlexa || noResponse}</pre>
              {linkAlexa ? (
                <audio
                  src={linkAlexa}
                  preload="auto"
                  controls
                  className="w-full mb-5"
                  key={`${excelId}-${key}-alexa-audio`}
                />
              ) : (
                <p className="text-base mb-5">{noResponse}</p>
              )}
            </div>
            <div className="lg:w-96 w-full border border-solid border-stone-300">
              {linkAlexaImg ? <img src={linkAlexaImg} /> : <p className="text-base p-4">{noResponse}</p>}
            </div>
          </div>
        </div>

        <div className="shadow-md rounded-3xl mb-10 bg-white">
          <div className="bg-teal-500 px-5 flex rounded-t-3xl overflow-show">
            <img
              src={imgSiri}
              className="bg-white p-1 w-16 h-16 rounded-full shadow-xl -mt-5 mr-3 border border-solid border-stone-200"
            />
            <p className="text-white font-bold py-4 text-xl">Siri</p>
          </div>
          <div className="w-full shadow-xl rounded-b-3xl p-5 flex lg:flex-row flex-col">
            <div className="flex-1 mr-5">
              <pre className="text-md mb-5 whitespace-pre-line">{textSiri || noResponse}</pre>
            </div>
            <div className="lg:w-96 w-full border border-solid border-stone-300">
              {imageSiri ? <img src={imageSiri} /> : <p className="text-base p-4">{noResponse}</p>}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between items-center">
        <div className="mb-5 flex flex-1 mr-5">
          <div className="mr-3 flex items-center">
            <img src={imgQuestionMark} className="w-24 h-24" />
          </div>
          <div className="w-[75%]">
            <div className="flex">
              <div>
                {queryType == QueryType.EXCEL && index !== null && (
                  <div className="flex mb-3">
                    <button
                      className="uppercase flex font-medium text-gray-600 dark:text-gray-500 hover:underline"
                      disabled={index == 0}
                      onClick={() => onNavigate(-1)}
                    >
                      <ChevronLeftIcon className="w-5 h-5 mr-3" />
                      Previous
                    </button>
                    <p className="text-md text-stone-500 uppercase px-10">
                      RESULT <b>{index + 1}</b> of {count}
                    </p>
                    <button
                      className="uppercase flex font-medium text-gray-600 dark:text-gray-500 hover:underline"
                      disabled={index == count - 1}
                      onClick={() => onNavigate(1)}
                    >
                      Next
                      <ChevronRightIcon className="w-5 h-5 ml-3" />
                    </button>
                  </div>
                )}
                <p className="text-2xl mb-3 flex items-center">
                  {query}
                  <p className="text-sm text-gray ml-3">{completedDate}</p>
                </p>
              </div>
            </div>
            <div className="relative h-10">
              {!isLoading && details && (
                <>
                  {details.linkPolly ? (
                    <audio src={details.linkPolly} preload="auto" controls className="w-full h-10" />
                  ) : (
                    <p className="text-base">{noResponse}</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <Button className="mt-1" size="sm" color="teal" bold onClick={onBack}>
          <span className="flex w-full items-center justify-center">
            <ArrowLeftIcon className="w-5 h-5 mr-2" /> Back
          </span>
        </Button>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-400 mb-10"></div>

      <div className="relative">
        {isLoading && (
          <div className="w-full h-96 absolute left-0 top-0 flex place-items-center bg-teal-50/50">
            <div className="w-full items-center flex flex-col">
              <RefreshIcon className="inline w-4 h-4 mr-3 text-black animate-spin" />
            </div>
          </div>
        )}
        {!isLoading && renderDetails()}
      </div>
    </div>
  )
}
