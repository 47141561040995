import humanizeDuration from 'humanize-duration'
import { UploadIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { uploadExcelFile } from 'services/apis'
import { Button, Input } from 'stories/components'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  title: '',
  email: '',
}

export function UploadExcel() {
  const navigate = useNavigate()
  const auth = useSelector((state: any) => state.auth)

  const [inputStates, setInputStates] = useState({
    ...defaultInputs,
    email: auth.profile.email,
  })
  const [errors, setErrors] = useState({
    title: '',
    email: '',
    file: '',
  })
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setLoading] = useState(false)

  // const onBack = () => {
  //   navigate('/excels')
  // }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)

    setErrors({
      ...errors,
      [key]: '',
    })
  }

  const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length == 0) setFile(null)
    else setFile(files.item(0))
    setErrors({
      ...errors,
      file: '',
    })
  }

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.title) newErrors.title = 'Required'
    if (!file) newErrors.file = 'Required'
    setErrors(newErrors)
    if (!inputStates.email || !inputStates.title || !file) return

    setLoading(true)
    uploadExcelFile(inputStates.title, inputStates.email, file)
      .then(({ count }) => {
        const duration = humanizeDuration(count * 1000 * 60)
        toast(`Uploaded successfully. Estimated time to completion: ${duration}`, { type: 'success' })
        navigate('/queries')
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">Upload a new Query List</p>
        {/* <Button className="mt-1" size="sm" color="teal" bold onClick={onBack}>
          <span className="flex w-full items-center justify-center">
            <ArrowLeftIcon className="w-5 h-5 mr-2" /> Back
          </span>
        </Button> */}
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="relative xl:w-[50%] w-full">
        <Input
          type="text"
          title="Title"
          value={inputStates.title}
          required
          error={errors.title ? 'Please provide a valid title.' : ''}
          onChange={(value) => onChange('title', value)}
        />
        <Input
          type="email"
          title="Email"
          value={inputStates.email}
          required
          error={errors.email ? 'Please provide a valid email address.' : ''}
          onChange={(value) => onChange('email', value)}
        />

        <div>
          <a href="/template.xlsx">
            <p className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">Download the template</p>
          </a>
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="excel_file">
            Upload
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-full cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="excel_file_help"
            id="excel_file"
            type="file"
            accept="application/vnd.*,.xls,.xlsx"
            required
            onChange={(event) => readFile(event)}
          />
          {errors.file ? (
            <p className="peer-invalid:visible text-rose-700 text-xs">Please provide an excel file.</p>
          ) : (
            <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="excel_file_help">
              An excel file with multi queries
            </div>
          )}
        </div>

        <Button className="mt-2" color="teal" bold onClick={onSubmit} loading={isLoading}>
          <span className="flex w-full items-center justify-center">
            Upload
            <UploadIcon className="w-5 h-5 ml-2" />
          </span>
        </Button>
      </div>
    </div>
  )
}
