import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { getExcelList } from 'services/apis'
import { setQueryList } from 'actions'

export const useUpdateQueries = () => {
  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()

  const { data, isLoading, error, refetch } = useQuery(
    'queries',
    async () => {
      return getExcelList()
    },
    {
      enabled: auth.isAuthenticated,
      refetchOnWindowFocus: true,
      retryDelay: 45000,
    },
  )

  useEffect(() => {
    data && setQueryList(data)(dispatch)
  }, [data])

  const hasPermission = useCallback(
    (permissionLevel: keyof Permissions) => {
      if (data === undefined) return undefined
      const matched = data?.role?.permissions?.find(
        (permission: { permissionName: string }) => permission.permissionName === permissionLevel,
      )
      if (matched === undefined) return false
      return true
    },
    [data],
  )
  return { data, isLoading, error, refetch, hasPermission }
}
