import {
  API_UPLOAD_EXCEL,
  API_GET_EXCEL,
  API_GET_EXCELS,
  API_GET_EXCEL_DETAILS,
  API_UPLOAD_QUERY,
  API_DELETE_EXCEL,
  API_GET_ALL_EXCELS,
  API_GET_EXCEL_SHARES,
  API_GET_EXCEL_SHARES_BY_USER,
} from 'config'
import Api from 'services/api'

export const getExcelList = () => {
  return Api.get(API_GET_EXCELS)
}

export const getExcelItem = (excelId: string) => {
  return Api.get(API_GET_EXCEL, {}, { excelId })
}

export const uploadExcelFile = (title: string, email: string, file: File) => {
  return Api.uploadFile(API_UPLOAD_EXCEL, { title, email }, {}, file)
}

export const uploadSingleQuery = (query: string, email: string) => {
  return Api.post(API_UPLOAD_QUERY, { query, email }, {})
}

export const getExcelDetails = (excelId: string, key: string) => {
  return Api.get(API_GET_EXCEL_DETAILS, {}, { excelId, key })
}

export const deleteExcelItem = (excelId: string) => {
  return Api.delete(API_DELETE_EXCEL, {}, { excelId })
}

export const filterExcelList = (filterData: any) => {
  return Api.get(API_GET_ALL_EXCELS, filterData)
}

export const getExcelSharedEmails = (id: number) => {
  return Api.get(API_GET_EXCEL_SHARES, {}, { id })
}

export const getExcelSharedEmailsByUser = (userId: number) => {
  return Api.get(API_GET_EXCEL_SHARES_BY_USER, {}, { id: userId })
}

export const shareExcelWithEmails = (id: number, emails: string[]) => {
  return Api.put(API_GET_EXCEL_SHARES, { emails }, { id })
}
