// import imgHome from 'assets/home.jpg'
import { Button, Input } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
import { useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signOnRequest } from 'actions'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  username: '',
  email: '',
  password: '',
}

export function Register(props: any) {
  useTitle(`Home - ${process.env.REACT_APP_COMPANY}`)

  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    username: '',
    email: '',
    password: '',
  })

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.username) newErrors.username = 'Required'
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.password) newErrors.password = 'Required'
    setErrors(newErrors)

    if (!inputStates.username || !inputStates.email || !inputStates.password) return

    await signOnRequest(inputStates)(dispatch)
    setLoading(false)
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative">
      <div className="absolute top-0 w-full">
        {/* <img className="w-full h-full object-cover inset-y-0" src={imgHome} alt="" /> */}
      </div>
      {!auth.isAuthenticated && (
        <div className="max-w-screen-2xl m-auto lg:pt-20 md:pt-5 lg:pl-20 md:pl-3 sm:pt-2 sm-pl-2 mb-10">
          <div className="bg-white drop-shadow-2xl rounded-2xl lg:max-w-md md:max-w-md sm:max-w-sm pl-6 pr-6 pt-8 pb-6 relative">
            <div className="text-base text-teal-500 text-3xl font-black mb-6 ">Register</div>

            <form>
              <Input
                type="text"
                title="Username"
                value={inputStates.username}
                required
                error={errors.username ? 'Please provide a valid username.' : ''}
                onChange={(value) => onChange('username', value)}
              />
              <Input
                type="email"
                title="Email"
                value={inputStates.email}
                required
                error={errors.email ? 'Please provide a valid email address.' : ''}
                onChange={(value) => onChange('email', value)}
              />
              <div className="my-7"></div>
              <Input
                type="password"
                title="Password"
                value={inputStates.password}
                required
                error={errors.password ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('password', value)}
              />

              <Button className="mt-1" color="teal" full bold onClick={onSubmit} loading={isLoading}>
                <span className="flex w-full items-center justify-center">
                  Register
                  <ChevronRightIcon className="w-5 h-5" />
                </span>
              </Button>

              <Link to="/home">
                <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">I have an account</span>
              </Link>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
