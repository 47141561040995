import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getExcelSharedEmails, getExcelSharedEmailsByUser, shareExcelWithEmails } from 'services/apis'
import { Modal } from 'stories/components'
import { renderInput } from 'utils/renderInput'

const defaultInputs = (): Record<string, InputType> => {
  return {
    emails: {
      inputType: 'AutoComplete',
      type: 'email',
      title: 'Share with (input emails)',
      value: [],
      error: '',
      required: true,
      visible: true,
    },
  }
}

export const ShareExcelModal = (props: any) => {
  const auth = useSelector((state: any) => state.auth)
  const [inputs, setInputs] = useState<Record<string, any>>(defaultInputs())
  const [candidates, setCandidates] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getExcelSharedEmails(props.id).then((data) => {
      let newInputs = cloneDeep(inputs)
      newInputs.emails.value = data
      setInputs(newInputs)
      setLoading(false)
    })

    getExcelSharedEmailsByUser(auth.profile.id).then((data) => {
      setCandidates(data)
    })
  }, [props.id])

  const invitableEmails = useMemo(() => {
    return candidates.filter((candi) => !inputs.emails.value.find((email: string) => email == candi))
  }, [inputs, candidates])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onAdd = (email: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs.emails.value.push(email)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    setLoading(true)

    const data: Record<string, any> = {}
    for (const key in inputs) {
      const { value } = inputs[key]
      data[key] = value
    }
    shareExcelWithEmails(props.id, data.emails)
      .then(() => {
        toast('The query has been shared successfully.', { type: 'success' })

        setLoading(false)
        props.onClose()
      })
      .catch(() => setLoading(false))
  }

  return (
    <Modal
      isOpen
      title="Share queries with"
      titleOkay="Share"
      loading={isLoading}
      onClose={props.onClose}
      onOk={onSubmit}
    >
      <div className="w-144 grid gap-4 grid-cols-1">
        {Object.keys(inputs).map((key: any, index) => {
          const input = inputs[key]
          return (
            <div className="input" key={index}>
              {renderInput(input, key, onChange)}
            </div>
          )
        })}

        <div>
          <p>Invited emails from other queries</p>
          <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white cursor-pointer">
            {invitableEmails.map((value, index) => {
              const classes = []
              if (index == 0) classes.push('rounded-t-lg')
              if (index == invitableEmails.length - 1) classes.push('rounded-b-lg')
              else classes.push('border-b border-gray-200 dark:border-gray-600')
              return (
                <li
                  className={`py-2 px-4 w-full hover:bg-gray-100 ${classes.join(' ')}`}
                  key={index}
                  onClick={() => onAdd(value)}
                >
                  {index + 1}. {value}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Modal>
  )
}
