import { ArrowRightIcon } from '@heroicons/react/outline'
import { UPDATE_PROFILE } from 'actions'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { updateProfile } from 'services/apis'
import { Button, Input } from 'stories/components'
import { UpdatePassword } from './UpdatePassword'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  username: '',
  email: '',
  role: '',
}

export function MyProfile() {
  const profile = useSelector((state: any) => state.auth.profile)

  const [inputStates, setInputStates] = useState({
    ...defaultInputs,
  })
  const [errors, setErrors] = useState({
    username: '',
    email: '',
  })
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setInputStates(profile)
  }, [profile])

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)

    setErrors({
      ...errors,
      [key]: '',
    })
  }

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.username) newErrors.username = 'Required'
    setErrors(newErrors)
    if (!inputStates.email || !inputStates.username) return

    setLoading(true)
    updateProfile(inputStates)
      .then(() => {
        toast('Uploaded successfully.', { type: 'success' })
        dispatch({ type: UPDATE_PROFILE, user: inputStates })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">My Profile</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative w-full">
          <Input
            type="text"
            title="Name"
            value={inputStates.username}
            required
            error={errors.username ? 'Please provide a valid name.' : ''}
            onChange={(value) => onChange('username', value)}
          />
          <Input
            type="email"
            title="Email"
            value={inputStates.email}
            required
            error={errors.email ? 'Please provide a valid email address.' : ''}
            onChange={(value) => onChange('email', value)}
          />

          <Input type="text" title="Role" value={inputStates.role} disabled />

          <Button className="mt-2" color="teal" bold onClick={onSubmit} loading={isLoading}>
            <span className="flex w-full items-center justify-center">
              Submit
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </span>
          </Button>
        </div>

        <UpdatePassword />
      </div>
    </div>
  )
}
