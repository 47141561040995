import type { InputType } from 'config'
import { AutoComplete, Input, Select, Toggle } from 'stories/components'

export const renderInput = (input: InputType, key: string, onChange: Function, showHistory: Function = () => {}) => {
  let required = input.required
  let { history, title, error = '', disabled, value = '' } = input
  required = required && !disabled
  if (!(input.inputType as any)) input.inputType = 'Text' as any

  switch (input.inputType) {
    case 'Text':
    case 'text':
      return (
        <Input
          title={title}
          key={key}
          type={input.type}
          error={error}
          value={value as string}
          disabled={disabled}
          required={required}
          history={history}
          onChange={(value) => onChange(key, value)}
          showHistory={() => showHistory(key)}
        />
      )
    case 'Select':
    case 'select':
      return (
        <Select
          id={key}
          title={title}
          options={input.options}
          key={key}
          error={error}
          disabled={disabled}
          value={input.value as string}
          hasDefaultOption={input.hasDefaultOption}
          defaultOptionText={input.defaultOptionText}
          required={required}
          history={history}
          onChange={(value) => onChange(key, value)}
          showHistory={() => showHistory(key)}
        />
      )
    case 'Toggle':
    case 'toggle':
      return (
        <Toggle
          className="pt-1"
          id={key}
          title={title}
          key={key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(key, value)}
          showHistory={() => showHistory(key)}
        />
      )
    case 'AutoComplete':
    case 'autocomplete':
      return (
        <AutoComplete
          className="pt-1"
          title={title}
          key={key}
          disabled={disabled}
          error={error}
          type={input.type}
          required={input.required}
          value={input.value}
          onChange={(value) => onChange(key, value)}
        />
      )
  }
}
