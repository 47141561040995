export enum UserStatus {
  ALL = 'all',
  Active = 'active',
  Inactive = 'inactive',
}

export enum ExcelStatus {
  UPLOADING = 'uploading',
  INPROGRESS = 'inprogress',
  COMPLETED = 'completed',
}

export enum QueryType {
  EXCEL = 'excel',
  SINGLE = 'single',
}

export enum UserRole {
  VIEWER = 'viewer',
  USER = 'user',
  POWER = 'power',
  ADMIN = 'admin',
}

export const UserRoleOptions = {
  viewer: 'Viewer',
  user: 'User',
  power: 'Power',
  admin: 'Admin',
}
