import { imgHome } from 'assets'
import { Header } from 'stories/layouts'
// import { Footer } from 'stories/layouts'

// const footerNavigation = [{ name: 'Home', href: 'https://answervox.com' }]

export const AuthLayout = (props: any) => {
  const { children } = props

  return (
    <div className="relative bg-white overflow-hidden h-full-screen">
      <img src={imgHome} className="absolute right-0 bottom-0 top-0 h-full max-w-none" />
      <div className="home-left-bottom" />
      <Header />
      <main className="relative mx-auto">{children}</main>
    </div>
  )
}
