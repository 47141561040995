import { ON_SET_ACTIVE_QUERY, SET_QUERY_LIST } from 'actions'
import { createReducer } from 'utils'

const initialState = {
  queryList: [],
  queryData: {},
  queryType: null,
}
export const query = createReducer(initialState, {
  [ON_SET_ACTIVE_QUERY](state: any, { queryData, queryType }: any) {
    return {
      ...state,
      queryData,
      queryType,
    }
  },
  [SET_QUERY_LIST](state: any, { data }: any) {
    return {
      ...state,
      queryList: data,
    }
  },
})
