import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { imgLogo } from 'assets'
import { UserRole } from 'config'
import cloneDeep from 'clone-deep'

type tpNavigation = {
  [key: string]: any
}

const _navigations: tpNavigation = {
  single: { name: 'Single Query', href: '/single' },
  upload: { name: 'Upload List', href: '/upload' },
  queries: { name: 'View History', href: '/queries' },
  allqueries: { name: 'All History', href: '/allqueries' },
  users: { name: 'User Management', href: '/users' },
  server: { name: 'Server', href: '/server' },
  myprofile: { name: 'Profile', href: '/myprofile' },
  about: { name: 'About', href: '/about' },
  report: {
    name: 'Report Problem',
    href: 'https://eversana.sharepoint.com/teams/AnswerVoxFER/Lists/AnswerVox%20Feedback%20and%20Error%20Reporting/AllItems.aspx',
  },
  updates: {
    name: 'Updates',
    href: 'https://forms.office.com/Pages/ResponsePage.aspx?id=5wv05Eqvxkisp7BiGM_HXua4lfNfRfVJjPT6BaRBktJUM0lCQjk0TTc0RVVKVldZRzAxWkIyUUlHRi4u&wdLOR=c5A5E7BCA-36A5-4FBE-9F20-FC09C7CE2483',
  },
  logout: { name: 'Logout', href: '/logout' },
}

export function Header(props: any) {
  const auth = useSelector((state: any) => state.auth)
  const role: UserRole = useSelector((state: any) => state.auth.profile.role)
  const [navigations, setNavigations] = useState<Record<string, any>>({})
  const location = useLocation()
  const activeMenu = (to: string) => {
    if (location.pathname === to || location.pathname.startsWith(to)) {
      return 'text-gray-900 font-bold'
    }
    return ''
  }

  useEffect(() => {
    const newNav = cloneDeep(_navigations)
    if ([UserRole.VIEWER].includes(role)) {
      delete newNav.single
      delete newNav.upload
    }
    if (![UserRole.POWER, UserRole.ADMIN].includes(role)) delete newNav.allqueries
    if (role != UserRole.ADMIN) {
      delete newNav.users
      delete newNav.server
      delete newNav.about
    }

    setNavigations(newNav)
  }, [])

  return (
    <Popover>
      <div className="m-auto relative py-4 px-8">
        <nav className="relative flex items-center justify-between" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/home">
                <span className="sr-only">Answervox</span>
                <img alt="answervox" className="h-20 w-auto" src={imgLogo} />
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden flex-wrap items-center md:flex md:ml-10 md:pr-4 md:space-x-8">
            {auth.isAuthenticated &&
              Object.keys(navigations).map((key, index) => {
                const item: any = navigations[key]
                if (item.href.startsWith('http'))
                  return (
                    <a
                      key={`nav-${index}`}
                      href={item.href}
                      target="_blank"
                      className={`px-3 py-2 text-gray-600 hover:text-gray-900 uppercase ${activeMenu(item.href)}`}
                    >
                      {item.name}
                    </a>
                  )
                return (
                  <Link
                    key={`nav-${index}`}
                    to={item.href}
                    className={`px-3 py-2 text-gray-600 hover:text-gray-900 uppercase ${activeMenu(item.href)}`}
                  >
                    {item.name}
                  </Link>
                )
              })}
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-20 w-auto" src={imgLogo} alt="" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1"></div>
            <div>
              <div className="block rounded h-px bg-gray-100"></div>
              {auth.isAuthenticated &&
                Object.keys(navigations).map((key, index) => {
                  const item: any = navigations[key]
                  return (
                    <Link
                      key={index}
                      to={item.href}
                      className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                        item.href,
                      )}`}
                    >
                      {item.name}
                    </Link>
                  )
                })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
