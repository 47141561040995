import cloneDeep from 'clone-deep'
import { InputType, UserRoleOptions } from 'config'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { inviteUser } from 'services/apis'
import { Modal } from 'stories/components'
import { renderInput } from 'utils/renderInput'
import { InputValidate } from 'utils/validator'

const defaultInputs = (): Record<string, InputType> => {
  return {
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      value: '',
      error: '',
      required: true,
      visible: true,
    },
    role: {
      title: 'Role',
      inputType: 'Select',
      options: UserRoleOptions,
      value: '',
      error: '',
      required: true,
      hasDefaultOption: true,
      visible: true,
    },
  }
}

export const InviteUserModal = (props: any) => {
  const [inputs, setInputs] = useState<Record<string, any>>(defaultInputs())
  const [isLoading, setLoading] = useState(false)

  const submittable = useMemo(() => {
    let result = true
    Object.keys(inputs).map((key: any) => {
      const input = inputs[key]
      result = result && !InputValidate(input)
    })
    return result
  }, [inputs])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    setLoading(true)

    const data: Record<string, any> = {}
    for (const key in inputs) {
      const { value } = inputs[key]
      data[key] = value
    }
    inviteUser(data as any)
      .then(() => {
        toast('The user is invited successfully.', { type: 'success' })

        setLoading(false)
        props.onClose(true)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Modal
      isOpen
      title="Invite a new user"
      titleOkay="Invite"
      disabled={!submittable}
      loading={isLoading}
      onClose={props.onClose}
      onOk={onSubmit}
    >
      <div className="w-96 grid gap-4 grid-cols-1">
        {Object.keys(inputs).map((key: any, index) => {
          const input = inputs[key]
          return (
            <div className="input" key={index}>
              {renderInput(input, key, onChange)}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
