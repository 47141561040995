import moment from 'moment'

export const formatTime = (time: Date) => {
  return moment(time).format('MM/DD/YYYY HH:mm')
}

export function phoneConvertor(val: any) {
  try {
    let rlt = ''
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 10) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 10) {
      rlt = `(${rlt.substring(0, 3)}) ${rlt.substring(3, 6)}-${rlt.substring(6, 10)}`
    }
    return rlt
  } catch {
    return ''
  }
}

export const InputConvert = function (data: any, value: any) {
  try {
    if (data.type === 'phone') {
      value = phoneConvertor(value)
    }
  } catch {}
  return value
}
