import {
  About,
  AllExcels,
  ExcelDetails,
  Excels,
  Logout,
  MyProfile,
  Server,
  UploadExcel,
  UploadQuery,
  UserManagement,
} from 'pages'
import { QueryDetails } from 'pages/Excel/QueryDetails'
import { Routes, Route, Navigate } from 'react-router-dom'
// import { ProtectedRoute } from './protected.route'

export function MainRoute() {
  return (
    <Routes>
      <Route path="/queries" element={<Excels />} />
      <Route path="/allqueries" element={<AllExcels />} />
      <Route path="/upload" element={<UploadExcel />} />
      <Route path="/single" element={<UploadQuery />} />
      <Route path="/queries/:excelId" element={<ExcelDetails />} />
      <Route path="/queries/:excelId/:index" element={<QueryDetails />} />

      <Route path="/myprofile" element={<MyProfile />} />
      <Route path="/users" element={<UserManagement />} />

      <Route path="/server" element={<Server />} />
      <Route path="/about" element={<About />} />

      <Route path="/logout" element={<Logout />} />
      <Route path="/*" element={<Navigate replace to="/queries" />} />
    </Routes>
  )
}
