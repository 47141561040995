export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST'
export const AUTH_TOKEN_SET = 'AUTH_TOKEN_SET'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const ON_APPLICATION_ERROR = 'ON_APPLICATION_ERROR'

export const USER_PERMISSIONS_SET = 'USER_PERMISSIONS_SET'

//Audit Log Types
export const CLOSE_AUDIT_LOG = 'CLOSE_AUDIT_LOG'
export const OPEN_AUDIT_LOG = 'OPEN_AUDIT_LOG'
export const LOADED_AUDIT_LOG = 'LOADED_AUDIT_LOG'

export const ON_SET_ACTIVE_QUERY = 'ON_SET_ACTIVE_QUERY'
export const SET_QUERY_LIST = 'SET_QUERY_LIST'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
