import { MoonIcon, SunIcon } from '@heroicons/react/outline'
import cloneDeep from 'clone-deep'
import { countPerPage as _countPerPage } from 'config'
import { useState, useEffect } from 'react'
// import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { fetchServerLog, fetchServerMonitor, fetchServerStatus } from 'services/apis'
import svgLoading from 'stories/assets/loading.svg'
import { Pagination, Select } from 'stories/components'
import { formatTime } from 'utils/convertor'

const serverTypes = ['Alexa', 'Siri', 'Html2Image', 'Lambda', 'Api']
const logTypes = ['verbose', 'info', 'warning', 'error']

export function Server() {
  const auth = useSelector((state: any) => state.auth)
  const [countPerPage, setCountPerPage] = useState(_countPerPage)

  const { data: status, isLoading: isStatusLoading } = useQuery('status', fetchServerStatus, {
    enabled: auth.isAuthenticated,
    refetchOnWindowFocus: true,
    retryDelay: 45000,
  })

  const {
    data: logs,
    isLoading: isLogLoading,
    refetch: refetchLog,
  } = useQuery('logs', () => fetchServerLog({ ...logFilters, skip: pageNum * countPerPage }), {
    enabled: auth.isAuthenticated,
    refetchOnWindowFocus: true,
    retryDelay: 45000,
  })

  const { data: monitor, isLoading: isMonitorLoading } = useQuery('monitor', fetchServerMonitor, {
    enabled: auth.isAuthenticated,
    refetchOnWindowFocus: true,
    retryDelay: 45000,
  })

  const [logFilters, setLogFilters] = useState({
    serverType: '',
    logType: '',
    take: countPerPage,
    skip: 0,
  })
  const [pageNum, setPageNum] = useState(0)

  useEffect(() => {
    refetchLog()
  }, [logFilters, pageNum])

  const onChange = (key: 'serverType' | 'logType', value: string) => {
    const newFilters = cloneDeep(logFilters)
    newFilters[key] = value
    setLogFilters(newFilters)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    setCountPerPage(count)
    setPageNum(0)
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">Server</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full mb-4">
        {isStatusLoading && <img src={svgLoading} className="inline w-6 h-6 ml-3 text-white animate-spin" />}
        {status &&
          Object.keys(status).map((key) => (
            <div className="flex items-center" key={key}>
              {status[key] ? (
                <SunIcon className="w-5 h-5 ml-2 text-teal-500" />
              ) : (
                <MoonIcon className="w-5 h-5 ml-2 text-gray-500" />
              )}
              <p className="text-gray-500 capitalize">{key}</p>
            </div>
          ))}
      </div>

      <div className="md:flex w-full">
        <div className="flex-[3]">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Select
              title="Service"
              id="service"
              value={logFilters.serverType}
              options={serverTypes}
              hasDefaultOption
              defaultOptionText="All"
              onChange={(value) => onChange('serverType', value)}
            />
            <Select
              title="Log"
              id="log"
              value={logFilters.logType}
              options={logTypes}
              hasDefaultOption
              defaultOptionText="All"
              onChange={(value) => onChange('logType', value)}
            />
          </div>
          <div className="bg-gray-900 p-2 overflow-scroll text-left h-[70vh]">
            {isLogLoading && <img src={svgLoading} className="inline w-6 h-6 ml-3 text-white animate-spin" />}
            <div className="flex text-white mb-2">
              <p className="flex-1">Legend: [A]lexa, [L]ambda, [S]iri, [H]tml2img, A[P]I</p>
              <div className="flex gap-1">
                <p className="text-red-500">Error</p>|<p className="text-yellow-300">Warning</p>|
                <p className="text-green-500">Success</p>|<p className="text-gray-100">Informational</p>
              </div>
            </div>
            {logs &&
              logs.data.map((log: Record<string, any>, index: number) => {
                let color = 'text-white'
                switch (log.logType) {
                  case 'verbose':
                    color = 'text-gray-100'
                    break
                  case 'info':
                    color = 'text-green-500'
                    break
                  case 'warning':
                    color = 'text-yellow-300'
                    break
                  case 'error':
                    color = 'text-red-500'
                    break
                }
                const prefix = log.serverType === 'Api' ? 'P' : log.serverType[0]
                return (
                  <div key={index} className={`flex ${color}`}>
                    <p className="mr-1">[{prefix}]</p>
                    <p className="mr-2 w-32">{formatTime(log.createdAt)}</p>
                    <p className="flex-1">{log.text}</p>
                  </div>
                )
              })}
          </div>

          {logs && (
            <Pagination
              totalCount={logs.total}
              itemCountPerPage={countPerPage}
              pageNum={pageNum}
              maxPageCount={10}
              onNavigate={onPageNavigate}
              onChangeCountPerPage={onChangeCountPerPage}
            />
          )}
        </div>
        <div className="flex-[1] h-[85vh] bg-gray-100 ml-2 overflow-scroll">
          {isMonitorLoading && <img src={svgLoading} className="inline w-6 h-6 ml-3 text-white animate-spin" />}
          {monitor &&
            monitor.map((state: Record<string, any>, index: number) => {
              const color = state.status ? 'green' : 'yellow'

              return (
                <div key={index} className={`border-l border-l-[5px] mb-2 pl-1 border-${color}-400`}>
                  <p>{state.text}</p>
                  <p className="mr-2 text-gray-500">{formatTime(state.createdAt)}</p>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
