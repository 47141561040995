import { ClipboardIcon, CollectionIcon, DownloadIcon, ShareIcon, TrashIcon } from '@heroicons/react/outline'
import { Tooltip } from '@material-tailwind/react'
import { onSetActiveQuery } from 'actions'
import { countPerPage, defaultKey, ExcelStatus, QueryType, s3Url } from 'config'
import { useUpdateQueries } from 'hooks/useUpdateQueries'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteExcelItem } from 'services/apis'
import { Pagination } from 'stories/components'
import { confirm } from 'utils'
import { formatTime } from 'utils/convertor'
import { ShareExcelModal } from './excelModal/shareExcelModal'

export function Excels() {
  const auth = useSelector((state: any) => state.auth)
  const list = useSelector((state: any) => state.query.queryList) as Array<any>
  const navigate = useNavigate()
  const [pageNum, setPageNum] = useState(0)
  const dispatch = useDispatch()
  const { refetch } = useUpdateQueries()
  const [_countPerPage, setCountPerPage] = useState(countPerPage)
  const [sharingItem, setSharingItem] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    refetch()
  }, [])

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    setCountPerPage(count)
    setPageNum(0)
  }

  const renderType = (type: QueryType) => {
    switch (type) {
      case QueryType.EXCEL:
        return <CollectionIcon className="w-5 h-5 text-teal-500" />
      case QueryType.SINGLE:
        return <ClipboardIcon className="w-5 h-5 text-yellow-500" />
    }
  }

  const renderStatus = ({ status: excelStatus, user: userId }: { status: ExcelStatus; user: number }) => {
    switch (excelStatus) {
      case ExcelStatus.INPROGRESS:
        return (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-200 dark:text-yellow-900">
            InProgress
          </span>
        )
      case ExcelStatus.COMPLETED:
        if (userId == auth.profile.id)
          return (
            <span className="bg-teal-100 text-teal-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-teal-200 dark:text-teal-900">
              Completed
            </span>
          )
        else
          return (
            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-200 dark:text-green-900">
              Shared
            </span>
          )
    }
  }

  const onDetails = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: any) => {
    if (e.defaultPrevented) return
    if (item.status == ExcelStatus.INPROGRESS) {
      toast('This item is still In Progress', { type: 'warning' })
      return
    }
    if (item.type == QueryType.EXCEL) {
      dispatch(onSetActiveQuery(null, QueryType.EXCEL))
      navigate(`/queries/${item.excelId}`)
    } else if (item.type == QueryType.SINGLE) {
      dispatch(onSetActiveQuery(null, QueryType.SINGLE))
      navigate(`/queries/${item.excelId}/${defaultKey}`, {
        state: { query: item.title },
      })
    }
  }

  const onDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    const url = `${s3Url}/${item.excelId}/target.xls`
    window.open(url, '_blank')
  }

  const onShare = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    setSharingItem(item)
  }

  const onRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    e.preventDefault()
    const result = await confirm('Do you want to remove this query?')
    if (result) {
      const { excelId } = item
      await deleteExcelItem(excelId)
      refetch()
    }
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">Query Lists</p>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-teal-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Title / Query
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Query Count
              </th>
              <th scope="col" className="px-6 py-3">
                Created At
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map &&
              typeof list.map == 'function' &&
              list.map((item: any, index: number) => {
                if (index < pageNum * _countPerPage || (pageNum + 1) * _countPerPage <= index) return null
                const isCompleted = item.status === ExcelStatus.COMPLETED
                const completedCount = isCompleted ? item.count : item.completedCount
                const isMyExcel = item.user === auth.profile.id
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                    key={`${index}-${item.excelId}`}
                    onClick={(e) => onDetails(e, item)}
                  >
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + 1}
                    </th>
                    <td className="px-6 py-4">{renderType(item.type)}</td>
                    <td className="px-6 py-4">{item.title}</td>
                    <td className="px-6 py-4">{item.email}</td>
                    <td className="px-6 py-4">
                      <div>
                        {completedCount} / {item.count}
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${(completedCount / item.count) * 100}%` }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">{formatTime(item.createdAt)}</td>
                    <td className="px-6 py-4">{renderStatus(item)}</td>
                    <td className="px-6 py-2 text-right">
                      {item.type == QueryType.EXCEL && isCompleted && (
                        <Tooltip content="Download">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onDownload(e, item)}
                          >
                            <DownloadIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                      {isCompleted && (
                        <Tooltip content="Share">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onShare(e, item)}
                          >
                            <ShareIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                      {isCompleted && isMyExcel && (
                        <Tooltip content="Delete">
                          <button
                            className="font-medium text-teal-600 dark:text-teal-500 hover:bg-gray-200 p-2 rounded-md"
                            onClick={(e) => onRemove(e, item)}
                          >
                            <TrashIcon className="w-5 h-5" />
                          </button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {list && (
          <Pagination
            totalCount={list.length}
            itemCountPerPage={_countPerPage}
            pageNum={pageNum}
            maxPageCount={10}
            onNavigate={onPageNavigate}
            onChangeCountPerPage={onChangeCountPerPage}
          />
        )}

        {sharingItem && <ShareExcelModal onClose={() => setSharingItem(null)} id={sharingItem.id} />}
      </div>
    </div>
  )
}
