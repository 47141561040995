import { ArrowLeftIcon, RefreshIcon } from '@heroicons/react/outline'
import { onSetActiveQuery } from 'actions'
import { countPerPage, QueryType } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getExcelItem } from 'services/apis'
import { Button, Pagination } from 'stories/components'
import { formatTime } from 'utils/convertor'

export function ExcelDetails() {
  const { queryData, queryType } = useSelector((state: any) => state.query)
  const [sheets, setSheets] = useState<Record<string, string>>({})
  const [completedStatus, setCompletedStatus] = useState<Record<string, number>>({})
  const [createdAt, setCreatedAt] = useState('')
  const navigate = useNavigate()
  const { excelId } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [pageNum, setPageNum] = useState(0)
  const [_countPerPage, setCountPerPage] = useState(countPerPage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!excelId) return
    if (queryType == QueryType.EXCEL && queryData) {
      setSheets(queryData)
      setLoading(false)
      return
    }
    getExcelItem(excelId).then(({ data, completedStatus, createdAt }) => {
      setSheets(data)
      setCompletedStatus(completedStatus)
      setCreatedAt(createdAt)
      setLoading(false)
    })
  }, [])

  const onBack = () => {
    navigate('/queries')
  }

  const onDetails = (key: string, index: number) => {
    dispatch(onSetActiveQuery(sheets, QueryType.EXCEL))

    navigate(`/queries/${excelId}/${index}`, { state: { query: sheets[key] } })
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeCountPerPage = (count: number) => {
    setCountPerPage(count)
    setPageNum(0)
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative h-50 w-full">
      <div className="flex justify-between h-14 items-center">
        <p className="text-3xl text-teal-500 font-bold">Query List Items</p>
        <Button className="mt-1" size="sm" color="teal" bold onClick={onBack}>
          <span className="flex w-full items-center justify-center">
            <ArrowLeftIcon className="w-5 h-5 mr-2" /> Back
          </span>
        </Button>
      </div>
      <div className="py-[1px] space-y-1 bg-stone-800 mb-5"></div>

      <div className="relative shadow-md sm:rounded-lg min-h-full bg-white">
        {isLoading && (
          <div className="w-full h-96 absolute left-0 top-0 flex place-items-center bg-teal-50/50">
            <div className="w-full items-center flex flex-col">
              <RefreshIcon className="inline w-4 h-4 mr-3 text-black animate-spin" />
            </div>
          </div>
        )}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-teal-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Query
              </th>
              <th scope="col" className="px-6 py-3">
                Created At
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(sheets).map((key, index: number) => {
              if (index < pageNum * _countPerPage || (pageNum + 1) * _countPerPage <= index) return null

              const item = sheets[key]
              const completedDate = completedStatus[key]
              return (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                  key={index}
                  onClick={() => onDetails(key, index)}
                >
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{item}</td>
                  <td className="px-6 py-4">
                    {typeof completedDate === 'number'
                      ? formatTime(new Date(completedDate))
                      : formatTime(new Date(createdAt))}
                  </td>
                  <td className="px-6 py-4 text-right">
                    <button className="font-medium text-teal-600 dark:text-teal-500 hover:underline">Details</button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          totalCount={Object.keys(sheets).length}
          itemCountPerPage={_countPerPage}
          pageNum={pageNum}
          maxPageCount={10}
          onNavigate={onPageNavigate}
          onChangeCountPerPage={onChangeCountPerPage}
        />
      </div>
    </div>
  )
}
