import { connect } from 'react-redux'
import { toast } from 'react-toastify'
// import imgHome from 'assets/home.jpg'
import { Button, Input, Toggle } from 'stories/components'
import { signInRequest } from 'actions'
import { useTitle } from 'utils/pageTitle'
import { FormEvent, useEffect, useState } from 'react'
import jwt from 'jwt-decode'
import { logout } from 'actions'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
  password: '',
}

function _Home(props: any) {
  useTitle(`Home - ${process.env.REACT_APP_COMPANY}`)

  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })

  const { auth } = props

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.password) newErrors.password = 'Required'
    setErrors(newErrors)

    if (!inputStates.email || !inputStates.password) return

    props.signInRequest(inputStates)
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  useEffect(() => {
    let success = false

    try {
      const payload: any = jwt(auth.token)
      if (payload.exp * 1000 > Date.now()) {
        success = true
      } else {
        toast(`Your token is expired!`, { type: 'error' })
      }
    } catch {}
    if (!success) {
      props.logout()
    }
  }, [])

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative">
      <div className="absolute top-0 w-full">
        {/* <img className="w-full h-full object-cover inset-y-0" src={imgHome} alt="" /> */}
      </div>
      {!auth.isAuthenticated && (
        <div className="max-w-screen-2xl m-auto lg:pt-20 md:pt-5 lg:pl-20 md:pl-3 sm:pt-2 sm-pl-2 mb-10">
          <div className="bg-white drop-shadow-2xl rounded-2xl lg:max-w-md md:max-w-md sm:max-w-sm pl-6 pr-6 pt-8 pb-6 relative">
            <div className="text-base text-teal-500 text-3xl font-black mb-6 ">Login</div>

            <form onSubmit={(e) => onSubmit(e)}>
              <Input
                type="email"
                title="Email"
                value={inputStates.email}
                required
                error={errors.email ? 'Please provide a valid email address.' : ''}
                onChange={(value) => onChange('email', value)}
              />
              <div className="my-7"></div>
              <Input
                type="password"
                title="Password"
                value={inputStates.password}
                required
                error={errors.password ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('password', value)}
              />

              <Toggle id="rememberme" title="Remember me" className="mb-3" />

              <Button type="submit" className="mt-1" color="teal" full bold loading={auth.fetchingToken}>
                <span className="flex w-full items-center justify-center">
                  Login
                  <ChevronRightIcon className="w-5 h-5" />
                </span>
              </Button>

              <Link to="/register">
                <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">Create an account</span>
              </Link>

              <Link to="/forgotPassword">
                <span className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">
                  Forgot/Reset Password
                </span>
              </Link>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state: any) {
  return {
    auth: state.auth,
  }
}

export const Login = connect(mapStateToProps, { logout, signInRequest })(_Home)
